/* Add the styles from your original HTML file here */
@font-face {
  font-family: 'Clash Display';
  src: url('ClashDisplay-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Clash Grotesk';
  src: url('ClashGrotesk-Light.woff2') format('woff2');
  font-weight: 300;
}
@font-face {
  font-family: 'Clash Grotesk';
  src: url('ClashGrotesk-Medium.woff') format('woff');
  font-weight: 500;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Clash Grotesk', sans-serif;
  background-color: #000;
  color: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
header {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}
.nav-link {
  color: white;
  text-decoration: none;
  padding: 8px;
  font-weight: 300;
  position: relative;
}
.nav-link:hover,
.nav-link:focus,
.nav-link.active {
  color: hsl(64, 91%, 78%);
  font-weight: 300;
}
.nav-link.active {
  font-weight: 500;
}
.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: hsl(64, 91%, 78%);
  border-radius: 50%;
}
.mobile-menu {
  display: none;
}
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
}
.hero p {
  font-size: 8vw;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
}
.highlight {
  color: hsl(64, 91%, 78%);
  font-family: 'Clash Display', sans-serif;
  font-weight: bold;
}
.logo {
  font-weight: bold;
  font-size: 1.6rem;
}
.about {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.section-title {
  font-size: 32px;
  font-weight: 500;
  color: #f2f2f2;
  display: flex;
  align-items: center;
}
.section-title::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: hsl(64, 91%, 78%);
  border-radius: 50%;
  margin-right: 12px;
}
.about-content {
  width: 60%;
}
.about p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 30px;
  font-weight: 300;
  color: #b4b4b4;
}
.about button {
  background: #333;
  border: none;
  padding: 12px 24px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 500;
  font-size: 16px;
}
.about button:hover {
  background-color: #444;
}
.separator {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px auto;
  max-width: 1200px;
}
.plugins {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 40px;
  background-color: #ffffff0d;
  border-radius: 20px;
}
.plugins-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}
.plugin-card {
  background-color: #111;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.plugin-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.plugin-card-content {
  padding: 20px;
}
.plugin-card h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.plugin-card p {
  font-size: 18px;
  color: #c3c3c3;
  font-weight: 200;
}
.services {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.services-content {
  width: 60%;
}
.service-point {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-weight: 300;
  color: #b4b4b4;
}
.service-point strong {
  color: #f2f2f2;
  font-weight: 500;
  font-size: 24px;
}
.contact {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 40px;
  text-align: center;
}
.contact h2 {
  font-size: 6vw;
  font-weight: 500;
  margin-bottom: 40px;
  color: #f2f2f2;
  line-height: 1.2;
}
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}
.contact-form input,
.contact-form textarea {
  background-color: #111;
  border: 1px solid #333;
  padding: 12px;
  margin-bottom: 20px;
  color: #f2f2f2;
  font-size: 16px;
}
.contact-form button {
  background-color: #f3fa94;
  color: #000;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}
.contact-form button:hover {
  background-color: hsl(64, 100%, 63%);
}
.footer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer-logo {
  font-size: 32px;
  font-weight: 500;
  color: #f2f2f2;
}
.footer-content {
  display: flex;
  justify-content: flex-end;
  gap: 60px;
}
.footer-nav,
.footer-social {
  display: flex;
  flex-direction: column;
}
.footer-nav a,
.footer-social a {
  color: #b4b4b4;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 10px;
}
.footer-nav a:hover,
.footer-social a:hover {
  color: #f3fa94;
}
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
.hamburger span {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
}

/* Add this to your existing CSS */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

.fade-in-delay-1 {
  animation-delay: 0.2s;
}

.fade-in-delay-2 {
  animation-delay: 0.4s;
}

.fade-in-delay-3 {
  animation-delay: 0.6s;
}

/* Add this to your existing CSS */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: hsl(64, 91%, 78%);
  font-size: 2rem;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-top: 8px solid hsl(64, 91%, 78%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}



@media (max-width: 768px) {
  header {
    padding: 40px;
  }
  .logo {
    font-weight: bold;
    font-size: 1.8rem;
  }
  .hero p {
    font-size: 14vw;
    padding: 40px 40px;
  }
  .about,
  .services,
  .footer {
    flex-direction: column;
    padding: 40px 40px;
  }
  .about-content,
  .services-content {
    width: 100%;
    margin-top: 30px;
  }
  .plugins-grid {
    grid-template-columns: 1fr;
  }
  .footer-content {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  .footer-social {
    margin-top: 20px;
  }
  .contact h2 {
    font-size: 36px;
  }
  nav {
    display: none;
  }
  .hamburger {
    display: flex;
  }
  .mobile-menu {
    display: none;
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    padding: 20px;
    align-items: center;
  }
  .mobile-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-menu a {
    color: white;
    text-decoration: none;
    padding: 10px 0;
    font-size: 18px;
    text-align: center;
  }
}
